


















import { Action, Getter } from "vuex-class";
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit, FormSelect } from "@/components/forms";

@Component({
  components: {
    LayoutModal: () => import("@/layouts/components/modal.vue"),
    FormBase,
    FormInput,
    FormSelect,
    FormError,
    FormSubmit,
  },
})
export default class ModalAccountInvite extends Vue {
  @Getter("workspace/all") workspaces!: Workspace[];

  @Action("workspace/index") indexWorksapces!: () => Promise<Workspace[]>;

  @Action("invite/create") createInvite!: (payload: InvitePayload) => Promise<void>;

  @Prop() open!: boolean;

  @Ref() form!: FormClass;

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  payload: InvitePayload = {
    email: "",
    first_name: "",
    last_name: "",
    role_id: NaN,
    locale: "nl_BE",
  };

  roleOptions: SelectOption[] = [
    { id: 1, label: "Gebruiker" },
    { id: 2, label: "Administrator" },
  ];

  mounted() {
    this.indexWorksapces();
  }

  private submit() {
    this.createInvite(Object.entries(this.payload).reduce((a: any, [k, v]) => (v ? ((a[k] = v), a) : a), {}))
      .then(() => this.$emit("close"))
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse))
      .finally(() => this.form.reset());
  }

  private handleRoleChanged(val: number) {
    if (val !== 1) {
      this.payload.workspace_id = NaN;
    }
  }

  private get workspaceOptions(): SelectOption[] {
    return this.workspaces.map((workspace: Workspace) => {
      return { id: workspace.id, label: workspace.title };
    });
  }
}
